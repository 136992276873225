import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"


import ImgServiciosEnLinea from "../images/cuenta-estandar/servicios-en-linea.gif"
import ImgDomiciliacion from "../images/productos/domiciliacion.png"
import ImgPagosRecurrentes from "../images/productos/pagos-recurrentes.png"


export const StyledText = styled.div`
  display: inline-block;
  max-width: 32rem;
`

export const StyledHeading = styled.h2`
  line-height: 1.125em;
  letter-spacing: -0.025em;
  color: rgb(25, 28, 31);
  font-size: 2.5rem;
  margin: 0px;

  @media (min-width: 1081px) {
    font-size: 3rem;
  }
`

export const StyledImage = styled.div`
  @media (max-width: 1080px) {
    margin-top: 6rem;
    max-height: 32rem;
  }
`

export const StyledAnimation = styled.div`
  margin: 0px auto;
  box-sizing: border-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const StyledSlide = styled.div`
  padding: 0px 3rem;
  margin: auto;
  max-width: 86rem;
  min-height: 100vh;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 519px) {
    padding: 0px 1.5rem;
    margin: 8.25rem 0px 12rem;
    height: auto;
    min-height: 0px;
    max-height: none;
  }

  @media (max-width: 1080px) {
    margin: 70px 0px;
    flex-direction: column-reverse;
    -moz-box-pack: center !important;
    justify-content: center !important;
    -moz-box-align: center !important;
    align-items: center !important;
    max-height: none;

    &:first-child {
      margin-top: auto;
    }

    & > div:first-child {
      margin-right: 0px;
    }

    & h2,
    & p {
      text-align: center;
    }
  }

  @media (min-width: 1081px) {
    ${ props => props.reverse ? "flex-direction: row-reverse" : "" };

    & ${StyledText} {
      ${ props => props.reverse ? "" : "margin-right: 4rem" };
    }

    & ${StyledImage} {
      ${ props => props.reverse ? "margin-right: 4rem" : "" };
    }
  }
`


const PayServicesPage = () => (
  <Layout>
    <SEO title="Pagos de servicios" />
    <StyledSlide>
      <StyledText>
        <StyledHeading>Simplifica tu vida diaria</StyledHeading>
        <p>
          Ahora puedes pagar tus facturas de agua, luz, internet y mucho más, directamente desde la App.<br />
          <br />
          Simplemente escanea el código de barras o tome una foto de tu recibo e indique la cantidad a pagar.<br />
          <br />
          <b>¡Así de sencillo!</b>
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgServiciosEnLinea} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide reverse={true}>
      <StyledText>
        <StyledHeading>Domiciliación</StyledHeading>
        <p>
          Utiliza KKO para domiciliar tus servicios.<br />
          <br />
          De esa manera no tendrás que preocuparte más por las fechas de corte ya que se realizarán los pagos automáticamente.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgDomiciliacion} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide>
      <StyledText>
        <StyledHeading>Haz pagos recurrentes</StyledHeading>
        <p>
          Utiliza KKO para cualquier pago recurrente.<br />
          <br />
          Tu renta, el mantenimiento, donativos o cualquier otro gasto que no se pueda domiciliar, solo tienes que programar la cantidad y la fecha! ¡Tu pago llegara a tiempo cada mes!
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgPagosRecurrentes} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
  </Layout>
)

export default PayServicesPage
